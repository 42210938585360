import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('jwt')
    if (token && config.headers) (config.headers as any).Authorization = `Bearer ${token}`
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(undefined, error => {
    if (error && error.response) {
      const {status, data, config} = error.response
      switch (status) {
      case 401:
        window.location.assign('/')
        break
      case 400:
        toast.error('400: Bad Request')
        break
      case 404:
        toast.error('404: Not Found')
        break
      case 500:
        toast.error('500: Internal Server Error')
        break
      }
      throw error.response
    }
})

const responseBody = (response: AxiosResponse) => response.data

export const pdfshift = (data:any) => {
  return new Promise((resolve, reject) => {
      let asJson = false
      if ('filename' in data || 'webhook' in data) {
          asJson  = true
      }

      axios.request({
          method: 'post',
          url: 'https://api.pdfshift.io/v3/convert/pdf',
          responseType: (asJson ? 'json' : 'arraybuffer'),
          data: data,
          auth: { username: 'api', password: '1eb98ac29282463fb2236a9abfcb1353' }
      }).then(resolve).catch(response => {
          reject(response)
      })
  })
}

const requests = {
    get: (url: string, params: URLSearchParams) =>
      axios
        .get(url, {params: params})
        .then(responseBody),
    post: (url: string, body: {}) =>
      axios
        .post(url, body)
        .then(responseBody),
    put: (url: string, body: {}) =>
      axios
        .put(url, body, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(responseBody),
    del: (url: string) =>
      axios
        .delete(url)
        .then(responseBody)
  }

const Auth = {
  authGoogle: (code: string) => requests.post('/staff/auth/google', { code: code }),
  fetchStaff: () => requests.get('/staff/current', new URLSearchParams()),
  fetchFacilities: () => requests.get(`/staff/facilities`, new URLSearchParams()),
  fetchFavorites: () => requests.get(`/staff/favorites`, new URLSearchParams()),
  authenticate: (username: string, password: string) => requests.post('/staff/authenticate', { username: username, password: password }),
  authenticate2: (username: string, password: string, code: string) => requests.post('/staff/authenticate2', { username: username, password: password, code: code }),
  validateReset: (code: string) => requests.post(`/staff/reset/validate/${code}`, {}),
  resetPassword: (code: string, password: string) => requests.post(`/staff/reset/password`, { code: code, password: password }),
  forgotPassword: (username: string) => requests.post(`/staff/forgot/password`, { username: username }),
  saveProfile: (data: any) => requests.post(`/staff/profile`, data),
  staffActivity: (seconds: number) => requests.post(`/staff/activity_log`, { seconds: seconds }),
  loginAs: (recordID: number) => requests.post(`/staff/login_as/${recordID}`, {}),
}

const Data = {
  fetchActiveInstructors: () => requests.get(`/data/active_instructors`, new URLSearchParams()),
  fetchAllInstructors: () => requests.get(`/data/all_instructors`, new URLSearchParams()),
  fetchPrograms: () => requests.get(`/data/programs`, new URLSearchParams()),
  fetchFunding: () => requests.get(`/data/funding`, new URLSearchParams()),
  fetchFacilities: () => requests.get(`/data/facilities`, new URLSearchParams()),
  fetchAllFacilities: () => requests.get(`/data/all_facilities`, new URLSearchParams()),
  fetchCertificate: (recordID: number) => requests.get(`/data/certificates/${recordID}`, new URLSearchParams()),
  fetchPositions: () => requests.get(`/data/positions`, new URLSearchParams()),
  fetchEthnicities: () => requests.get(`/data/ethnicities`, new URLSearchParams()),
  fetchCategories: () => requests.get(`/data/categories`, new URLSearchParams()),
  fetchThemes: () => requests.get(`/data/themes`, new URLSearchParams()),
  fetchGuides: (recordID: number) => requests.get(`/data/categories/${recordID}/guides`, new URLSearchParams()),
  fetchGuide: (recordID: number) => requests.get(`/data/guides/${recordID}`, new URLSearchParams()),
  fetchSchools: (params: URLSearchParams) => requests.get(`/data/schools`, params),
  fetchRequests: () => requests.get(`/data/requests`, new URLSearchParams()),
  fetchHolidays: () => requests.get(`/data/holidays`, new URLSearchParams()),
  fetchContracts: () => requests.get(`/data/contracts`, new URLSearchParams()),
  fetchHousing: () => requests.get(`/data/housing`, new URLSearchParams()),
  fetchCareers: () => requests.get(`/data/careers`, new URLSearchParams()),
  fetchFavorites: () => requests.get(`/data/favorites`, new URLSearchParams()),
  fetchAppSettings: () => requests.get(`/data/settings`, new URLSearchParams()),
  fetchTemplates: () => requests.get(`/data/templates/active`, new URLSearchParams()),
  fetchUniversal: (params: URLSearchParams) => requests.get(`/data/universal`, params),
  fetchInmates: (params: URLSearchParams) => requests.get(`/data/inmates`, params),
  searchClasses: (params: URLSearchParams) => requests.get(`/data/classes`, params),
  searchTemplates: (params: URLSearchParams) => requests.get(`/data/templates`, params),
  searchInmates: (params: URLSearchParams) => requests.get(`/data/inmates`, params),
  searchInstructors: (params: URLSearchParams) => requests.get(`/data/instructors`, params),
  saveSearch: (params: URLSearchParams) => requests.get(`/data/search`, params),
  schedules: (recordID: number) => requests.get(`/data/schedules/${recordID}`, new URLSearchParams()),
  attnDate: (recordID: number) => requests.get(`/data/next_attendance/${recordID}`, new URLSearchParams()),
}

const Schedules = {
  facilityOptions: (params: URLSearchParams) => requests.get(`/schedules/options`, params),
  generate: (params: URLSearchParams) => requests.get(`/schedules/generate`, params),
  missing: (params: URLSearchParams) => requests.get(`/schedules/missing`, params)
}

const Inmates = {
  fetchInmates: (params: URLSearchParams) => requests.get('/inmates/list', params),
  fetchSessions: (recordID: number, params: URLSearchParams) => requests.get(`/inmates/${recordID}/sessions`, params),
  fetchTABEs: (params: URLSearchParams) => requests.get('/inmates/list/tabe', params),
  fetchGEDs: (params: URLSearchParams) => requests.get('/inmates/list/ged', params),
  fetchHiSETs: (params: URLSearchParams) => requests.get('/inmates/list/hiset', params),
  fetchRIASECs: (params: URLSearchParams) => requests.get('/inmates/list/riasec', params),
  fetchANDs: (params: URLSearchParams) => requests.get('/inmates/list/ands', params),
  fetchUAs: (params: URLSearchParams) => requests.get('/inmates/list/uas', params),
  merge: (recordID: number, mergeID: number) => requests.post(`/inmates/${recordID}/merge/${mergeID}`, new URLSearchParams()),
  fetchInmate: (recordID: number) => requests.get(`/inmates/${recordID}`, new URLSearchParams()),
  fetchEducation: (recordID: number) => requests.get(`/inmates/${recordID}/education`, new URLSearchParams()),
  fetchMedical: (recordID: number) => requests.get(`/inmates/${recordID}/medical`, new URLSearchParams()),
  fetchMilitary: (recordID: number) => requests.get(`/inmates/${recordID}/military`, new URLSearchParams()),
  fetchDocuments: (recordID: number) => requests.get(`/inmates/${recordID}/documents`, new URLSearchParams()),
  fetchScreenings: (recordID: number) => requests.get(`/inmates/${recordID}/screenings`, new URLSearchParams()),
  editScreening: (recordID: number, screeningID: number) => requests.get(`/inmates/${recordID}/screenings/${screeningID}`, new URLSearchParams()),
  fetchFollowups: (recordID: number) => requests.get(`/inmates/${recordID}/followups`, new URLSearchParams()),
  editFollowup: (recordID: number, followupID: number) => requests.get(`/inmates/${recordID}/followups/${followupID}`, new URLSearchParams()),
  fetchAND: (recordID: number) => requests.get(`/inmates/${recordID}/ands`, new URLSearchParams()),
  editAND: (recordID: number, andID: number) => requests.get(`/inmates/${recordID}/ands/${andID}`, new URLSearchParams()),
  fetchUA: (recordID: number) => requests.get(`/inmates/${recordID}/uas`, new URLSearchParams()),
  editUA: (recordID: number, uaID: number) => requests.get(`/inmates/${recordID}/uas/${uaID}`, new URLSearchParams()),
  fetchLSIR: (recordID: number) => requests.get(`/inmates/${recordID}/lsir`, new URLSearchParams()),
  fetchPhaseEntries: (recordID: number) => requests.get(`/inmates/${recordID}/phase`, new URLSearchParams()),
  editPhaseEntry: (recordID: number, phaseID: number) => requests.get(`/inmates/${recordID}/phase/${phaseID}`, new URLSearchParams()),
  fetchChangelog: (recordID: number, params: URLSearchParams) => requests.get(`/inmates/${recordID}/changelog`, params),
  fetchClasses: (recordID: number, params: URLSearchParams) => requests.get(`/inmates/${recordID}/classes`, params),
  fetchTABE: (recordID: number, params: URLSearchParams) => requests.get(`/inmates/${recordID}/tabes`, params),
  fetchGED: (recordID: number, params: URLSearchParams) => requests.get(`/inmates/${recordID}/geds`, params),
  fetchHiSET: (recordID: number, params: URLSearchParams) => requests.get(`/inmates/${recordID}/hisets`, params),
  fetchRIASEC: (recordID: number) => requests.get(`/inmates/${recordID}/riasecs`, new URLSearchParams()),
  fetchReviews: (recordID: number) => requests.get(`/inmates/${recordID}/reviews`, new URLSearchParams()),
  fetchRefusals: (recordID: number) => requests.get(`/inmates/${recordID}/refusals`, new URLSearchParams()),
  fetchSignatory: (recordID: number) => requests.get(`/inmates/${recordID}/signatory`, new URLSearchParams()),
  fetchTranscriptRequests: (recordID: number) => requests.get(`/inmates/${recordID}/transcripts/requests`, new URLSearchParams()),
  fetchServiceRequests: (recordID: number) => requests.get(`/inmates/${recordID}/services`, new URLSearchParams()),
  saveIntake: (inmate: any) => requests.post(`/inmates/${inmate.recordID}`, inmate),
  saveHousing: (inmate: any) => requests.post(`/inmates/${inmate.recordID}/housing`, inmate),
  saveEmployment: (education: any) => requests.post(`/inmates/${education.inmateID}/employment`, education),
  saveLicenses: (education: any) => requests.post(`/inmates/${education.inmateID}/licenses`, education),
  saveEdContract: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/contract`, data),
  saveHS: (education: any) => requests.post(`/inmates/${education.inmateID}/hs`, education),
  saveCollege: (education: any) => requests.post(`/inmates/${education.inmateID}/college`, education),
  saveLearning: (education: any) => requests.post(`/inmates/${education.inmateID}/learning`, education),
  saveImprovement: (education: any) => requests.post(`/inmates/${education.inmateID}/improvement`, education),
  saveCommunity: (education: any) => requests.post(`/inmates/${education.inmateID}/community`, education),
  saveRecommendations: (education: any) => requests.post(`/inmates/${education.inmateID}/recommendations`, education),
  saveHealth: (medical: any) => requests.post(`/inmates/${medical.inmateID}/health`, medical),
  saveMilitary: (military: any) => requests.post(`/inmates/${military.inmateID}/military`, military),
  reprintRequest: (recordID: number, printID: string) => requests.post(`/inmates/${recordID}/transcripts/requests/${printID}/reprint`, {}),
  requestURL: (recordID: number, printID: string, data: any) => requests.post(`/inmates/${recordID}/transcripts/requests/${printID}/url`, data),
  saveCertificate: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/certificates/${data.linkID}/update`, data),
  removeCertificate: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/certificates/${data.linkID}/remove`, {}),
  restoreCertificate: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/certificates/${data.linkID}/restore`, {}),
  saveTranscript: (recordID: number, klass: any) => requests.post(`/inmates/${recordID}/transcripts/${klass.recordID}`, klass),
  removeTranscript: (recordID: number, klass: any) => requests.post(`/inmates/${recordID}/transcripts/${klass.recordID}/remove`, klass),
  addTranscriptNote: (recordID: number, klass: any) => requests.post(`/inmates/${recordID}/transcripts/${klass.recordID}/notes`, klass),
  addMilitaryNote: (recordID: number, military: any) => requests.post(`/inmates/${recordID}/military/notes`, military),
  addEducationNote: (recordID: number, education: any) => requests.post(`/inmates/${recordID}/education/notes`, education.data),
  addEmploymentNote: (recordID: number, education: any) => requests.post(`/inmates/${recordID}/employment/notes`, education.data),
  addHealthNote: (recordID: number, medical: any) => requests.post(`/inmates/${recordID}/health/notes`, medical),
  addDisabilityNote: (recordID: number, medical: any) => requests.post(`/inmates/${recordID}/disability/notes`, medical),
  addInfoNote: (recordID: number, info: any) => requests.post(`/inmates/${recordID}/notes`, info),
  editNote: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/notes/${data.recordID}`, data),
  removeNote: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/notes/${data.recordID}/remove`, {}),
  addCommunityNote: (recordID: number, education: any) => requests.post(`/inmates/${recordID}/community/notes`, education.data),
  addActivitiesNote: (recordID: number, education: any) => requests.post(`/inmates/${recordID}/activities/notes`, education.data),
  searchClasses: (recordID: number, params: URLSearchParams) => requests.get(`/inmates/${recordID}/classes/options`, params),
  followupRequest: (recordID: number, requestID: number, data:any = {}) => requests.post(`/inmates/${recordID}/transcripts/requests/${requestID}/followup`, data),
  removeRequest: (recordID: number, requestID: number, data:any = {}) => requests.post(`/inmates/${recordID}/transcripts/requests/${requestID}/remove`, data),
  receiveRequest: (recordID: number, requestID: number, data:any = {}) => requests.post(`/inmates/${recordID}/transcripts/requests/${requestID}/receive`, data),
  receiveService: (recordID: number, requestID: number) => requests.post(`/inmates/${recordID}/services/${requestID}/receive`, {}),
  generateService: (recordID: number) => requests.post(`/inmates/${recordID}/services`, {}),
  addClass: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/classes/${data.id}`, data),
  forceAddClass: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/classes/${data.id}/force`, data),
  addTABE: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/tabes`, data),
  saveTABE: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/tabes/${data.recordID}`, data),
  archiveHiSETs: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/hisets/archive`, data),
  archiveGEDs: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/geds/archive`, data),
  archiveTABEs: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/tabes/archive`, data),
  combineTABEs: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/tabes/combine`, data),
  addUA: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/uas`, data),
  saveUA: (recordID: number, uaID: number, data: any) => requests.post(`/inmates/${recordID}/uas/${uaID}`, data),
  addPhaseEntry: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/phase`, data),
  savePhaseEntry: (recordID: number, phaseID: number, data: any) => requests.post(`/inmates/${recordID}/phase/${phaseID}`, data),
  addFollowup: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/followups`, data),
  saveFollowup: (recordID: number, followupID: number, data: any) => requests.post(`/inmates/${recordID}/followups/${followupID}`, data),
  addScreening: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/screenings`, data),
  saveScreening: (recordID: number, screeningID: number, data: any) => requests.post(`/inmates/${recordID}/screenings/${screeningID}`, data),
  addAND: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/ands`, data),
  saveAND: (recordID: number, andID: number, data: any) => requests.post(`/inmates/${recordID}/ands/${andID}`, data),
  saveLSIR: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/lsir`, data),
  addCollege: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/colleges`, data),
  editCollege: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/colleges/${data.recordID}`, data),
  removeCollege: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/colleges/${data.recordID}/remove`, {}),
  addImprovement: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/improvements`, data),
  editImprovement: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/improvements/${data.recordID}`, data),
  removeImprovement: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/improvements/${data.recordID}/remove`, {}),
  addJob: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/jobs`, data),
  editJob: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/jobs/${data.recordID}`, data),
  removeJob: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/jobs/${data.recordID}/remove`, {}),
  addFacilityTransfer: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/transfers`, data),
  addGoal: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/goals`, data),
  editGoal: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/goals/${data.recordID}`, data),
  removeGoal: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/goals/${data.recordID}/remove`, {}),
  addCheckup: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/checkups`, data),
  addGED: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/geds`, data),
  saveGED: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/geds/${data.recordID}`, data),
  removeGED: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/geds/${data.recordID}/remove`, {}),
  addHiSET: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/hisets`, data),
  saveHiSET: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/hisets/${data.recordID}`, data),
  addRIASEC: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/riasecs`, data),
  saveRIASEC: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/riasecs/${data.recordID}`, data),
  removeRIASEC: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/riasecs/${data.recordID}/remove`, {}),
  addReview: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/reviews`, data),
  addRefusal: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/refusals`, data),
  editReview: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/reviews/${data.recordID}`, data),
  editRefusal: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/refusals/${data.recordID}`, data),
  removeReview: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/reviews/${data.recordID}/remove`, {}),
  removeRefusal: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/refusals/${data.recordID}/remove`, {}),
  removeSchedule: (data: any) => requests.post(`/inmates/${data.inmateID}/schedules/${data.scheduleID}/remove`, data),
  updateContract: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/contract`, data),
  facilityOptions: (recordID: number, facilityID: number) => requests.get(`/inmates/${recordID}/options/${facilityID}`, new URLSearchParams()),
  fetchFacilityTransfers: (recordID: number) => requests.get(`/inmates/${recordID}/transfers/facilities`, new URLSearchParams()),
  fetchHousingTransfers: (recordID: number) => requests.get(`/inmates/${recordID}/transfers/housing`, new URLSearchParams()),
  revertChange: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/revert`, data),
  favorite: (recordID: number) => requests.post(`/inmates/${recordID}/favorite`, {}),
  unfavorite: (recordID: number) => requests.post(`/inmates/${recordID}/unfavorite`, {}),
  uploadDocument: (recordID: number, data: FormData) => requests.put(`/inmates/${recordID}/documents`, data),
  saveDocument: (recordID: number, data: any) => requests.post(`/inmates/${recordID}/documents/${data.recordID}`, data),
  removeDocument: (recordID: number, documentID: number) => requests.post(`/inmates/${recordID}/documents/${documentID}/remove`, {}),
  restoreDocument: (recordID: number, documentID: number) => requests.post(`/inmates/${recordID}/documents/${documentID}/restore`, {}),
  assignCertificate: (recordID: number, certificateID: number, data: FormData) => requests.post(`/inmates/${recordID}/certificates/${certificateID}`, data)
}

const Classes = {
  fetchClasses: (params: URLSearchParams) => requests.get('/classes/list', params),
  fetchTemplates: (params: URLSearchParams) => requests.get('/classes/list/templates', params),
  fetchCertificates: (params: URLSearchParams) => requests.get('/classes/list/certificates', params),
  fetchWaitlist: (recordID: number) => requests.get(`/classes/waitlist/${recordID}`, new URLSearchParams()),
  fetchClass: (recordID: number) => requests.get(`/classes/${recordID}`, new URLSearchParams()),
  fetchRoster: (recordID: number) => requests.get(`/classes/${recordID}/roster`, new URLSearchParams()),
  fetchClassWaitlist: (recordID: number) => requests.get(`/classes/${recordID}/waitlist`, new URLSearchParams()),
  fetchClassApprovals: (recordID: number) => requests.get(`/classes/${recordID}/approvals`, new URLSearchParams()),
  fetchCompletions: (recordID: number) => requests.get(`/classes/${recordID}/completions`, new URLSearchParams()),
  fetchAttendance: (recordID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/attendance`, params),
  fetchAttendancePending: (recordID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/attendance/pending`, params),
  fetchAttendanceScheduled: (recordID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/attendance/scheduled`, params),
  fetchAttendanceRecords: (recordID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/attendance/records`, params),
  fetchAttendanceDisplace: (recordID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/attendance/displace`, params),
  fetchRosterAttendanceAdd: (recordID: number, rosterID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/rosters/${rosterID}/attendance/add`, params),
  fetchRosterAttendanceRemove: (recordID: number, rosterID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/rosters/${rosterID}/attendance/remove`, params),
  fetchSession: (recordID: number, sessionID: number) => requests.get(`/classes/${recordID}/session/${sessionID}`, new URLSearchParams()),
  fetchInstructors: (recordID: number) => requests.get(`/classes/${recordID}/roster`, new URLSearchParams()),
  searchClasses: (recordID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/options`, params),
  refreshCertificates: (recordID: number) => requests.get(`/classes/${recordID}/certificates`, new URLSearchParams()),
  submitPending: (recordID: number, body: any) => requests.post(`/classes/${recordID}/attendance`, body),
  updateRecord: (recordID: number, sessionID: number, body: any) => requests.post(`/classes/${recordID}/attendance/${sessionID}`, body),
  submitCompletions: (recordID: number, body: any) => requests.post(`/classes/${recordID}/completions`, body),
  searchInmates: (recordID: number, params: URLSearchParams) => requests.get(`/classes/${recordID}/inmates`, params),
  searchTemplateInmates: (recordID: number, params: URLSearchParams) => requests.get(`/classes/templates/${recordID}/inmates`, params),
  searchCertificateTemplates: (params: URLSearchParams) => requests.get(`/classes/templates/certificates`, params),
  searchTemplates: (params: URLSearchParams) => requests.get(`/classes/templates`, params),
  addInmates: (recordID: number, body: any) => requests.post(`/classes/${recordID}/inmates`, body),
  forceAddInmate: (recordID: number, data: any) => requests.post(`/classes/${recordID}/inmates/force`, data),
  deleteRoster: (recordID: number, rosterID: number, data:any) => requests.post(`/classes/${recordID}/rosters/${rosterID}/delete`, data),
  deleteBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/delete`, data),
  moveBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/move`, data),
  saveRoster: (recordID: number, roster: any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}`, roster),
  forceSaveRoster: (recordID: number, roster: any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}/force`, roster),
  waitlistRoster: (recordID: number, roster: any, data:any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}/waitlist`, data),
  suspendRoster: (recordID: number, roster: any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}/suspend`, {}),
  unsuspendRoster: (recordID: number, roster: any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}/unsuspend`, {}),
  quarantineRoster: (recordID: number, roster: any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}/quarantine`, {}),
  releaseRoster: (recordID: number, roster: any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}/release`, {}),
  addSessions: (recordID: number, rosterID: number, data:any) => requests.post(`/classes/${recordID}/rosters/${rosterID}/sessions/add`, data),
  removeSessions: (recordID: number, rosterID: number, data:any) => requests.post(`/classes/${recordID}/rosters/${rosterID}/sessions/remove`, data),
  addBulkSessions: (recordID: number, data:any) => requests.post(`/classes/${recordID}/bulk/sessions/add`, data),
  removeBulkSessions: (recordID: number, data:any) => requests.post(`/classes/${recordID}/bulk/sessions/remove`, data),
  removeSession: (recordID: number, sessionID: number) => requests.post(`/classes/${recordID}/sessions/${sessionID}/remove`, {}),
  addSession: (recordID: number, data:any) => requests.post(`/classes/${recordID}/sessions`, data),
  saveBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk`, data),
  forceSaveBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/force`, data),
  completeRoster: (recordID: number, roster: any) => requests.post(`/classes/${recordID}/rosters/${roster.recordID}/complete`, roster),
  completeBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/complete`, data),
  waitlistBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/waitlist`, data),
  suspendBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/suspend`, data),
  unsuspendBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/unsuspend`, data),
  quarantineBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/quarantine`, data),
  releaseBulk: (recordID: number, data: any) => requests.post(`/classes/${recordID}/rosters/bulk/release`, data),
  reDate: (recordID: number, data: any) => requests.post(`/classes/${recordID}/redate`, data),
  facilityOptions: (recordID: number, facilityID: number) => requests.get(`/classes/templates/${recordID}/options/${facilityID}`, new URLSearchParams()),
  templateOptions: (recordID: number) => requests.get(`/classes/templates/${recordID}/options`, new URLSearchParams()),
  certificateTemplateOptions: (recordID: number) => requests.get(`/classes/templates/${recordID}/options/certificates`, new URLSearchParams()),
  archiveClass: (recordID: number) => requests.post(`/classes/${recordID}/archive`, {}),
  restoreClass: (recordID: number) => requests.post(`/classes/${recordID}/restore`, {}),
  saveInfo: (klass: any) => requests.post(`/classes/${klass.recordID}`, klass),
  saveOptions: (klass: any) => requests.post(`/classes/${klass.recordID}/options`, klass),
  syncSchedules: (recordID: number, data: any) => requests.post(`/classes/${recordID}/schedules/sync`, data),
  syncCapacities: (recordID: number, data: any) => requests.post(`/classes/${recordID}/capacities/sync`, data),
  saveSchedule: (recordID: number, body: any) => requests.post(`/classes/${recordID}/schedule`, body),
  addSchedule: (recordID: number, body: any) => requests.post(`/classes/${recordID}/schedule/add`, body),
  removeSchedule: (recordID: number, scheduleID: number) => requests.post(`/classes/${recordID}/schedule/${scheduleID}/remove`, {}),
  groupSchedules: (recordID: number, body: any) => requests.post(`/classes/${recordID}/schedule/group`, body),
  ungroupSchedules: (recordID: number, body: any) => requests.post(`/classes/${recordID}/schedule/ungroup`, body),
  createClass: (recordID: number, data: any) => requests.post(`/classes/templates/${recordID}/create`, data),
  addWaitlist: (templateID: number, inmateID: number) => requests.post(`/classes/templates/${templateID}/waitlist/${inmateID}`, {}),
  addWaitlists: (templateID: number, data: any) => requests.post(`/classes/templates/${templateID}/waitlist`, data),
  removeWaitlist: (recordID: number) => requests.post(`/classes/waitlists/${recordID}/remove`, {}),
  removeClassWaitlist: (recordID: number, waitlistID: number) => requests.post(`/classes/${recordID}/waitlists/${waitlistID}/remove`, {}),
  convertClassWaitlist: (recordID: number, waitlistID: number) => requests.post(`/classes/${recordID}/waitlists/${waitlistID}/convert`, {}),
  denyClassApproval: (recordID: number, approvalID: number) => requests.post(`/classes/${recordID}/approvals/${approvalID}/deny`, {}),
  approveClassApproval: (recordID: number, approvalID: number) => requests.post(`/classes/${recordID}/approvals/${approvalID}/approve`, {}),
  createCertificate: (recordID: number, data: any) => requests.post(`/classes/${recordID}/certificates`, data),
  addHiatus: (recordID: number, data: any) => requests.post(`/classes/${recordID}/hiatus/new`, data),
  removeHiatus: (recordID: number, hiatusID: number) => requests.post(`/classes/${recordID}/hiatus/${hiatusID}/remove`, {}),
  saveHiatus: (recordID: number, data: any) => requests.post(`/classes/${recordID}/hiatus`, data),
  moveInmate: (recordID: number, rosterID: number, data: any) => requests.post(`/classes/${recordID}/rosters/${rosterID}/move`, data),
  displaceInmate: (recordID: number, rosterID: number, data: any) => requests.post(`/classes/${recordID}/rosters/${rosterID}/displace`, data),
  favorite: (recordID: number) => requests.post(`/classes/${recordID}/favorite`, {}),
  unfavorite: (recordID: number) => requests.post(`/classes/${recordID}/unfavorite`, {})
}

const Admin = {
  fetchLogUpdates: (params: URLSearchParams) => requests.get('/admin/logs/updates', params),
  fetchLogActivities: (params: URLSearchParams) => requests.get('/admin/logs/activities', params),
  fetchLogPDFs: (params: URLSearchParams) => requests.get('/admin/logs/pdfs', params),
  fetchLogOT: (params: URLSearchParams) => requests.get('/admin/logs/ot', params),
  fetchLogFlags: (params: URLSearchParams) => requests.get('/admin/logs/flags', params),
  fetchStaffList: () => requests.get(`/admin/staff`, new URLSearchParams()),
  fetchStaff: (recordID: number) => requests.get(`/admin/staff/${recordID}`, new URLSearchParams()),
  fetchCareer: (recordID: number) => requests.get(`/admin/careers/${recordID}`, new URLSearchParams()),
  fetchDepartmentOptions: (recordID: number, departmentID: number) => requests.get(`/admin/staff/${recordID}/options/department/${departmentID}`, new URLSearchParams()),
  fetchNewDepartmentOptions: (departmentID: number) => requests.get(`/admin/staff/options/department/${departmentID}`, new URLSearchParams()),
  fetchPrograms: () => requests.get(`/admin/programs`, new URLSearchParams()),
  fetchInstitutions: (params: URLSearchParams = new URLSearchParams()) => requests.get(`/admin/institutions`, params),
  fetchNews: () => requests.get(`/admin/news`, new URLSearchParams()),
  fetchAccess: () => requests.get(`/admin/access`, new URLSearchParams()),
  fetchFacilities: () => requests.get(`/admin/facilities`, new URLSearchParams()),
  fetchStaffFacilities: () => requests.get(`/admin/facilities/staff`, new URLSearchParams()),
  fetchFacility: (recordID: number) => requests.get(`/admin/facilities/${recordID}`, new URLSearchParams()),
  fetchFacilityOptions: (recordID: number, departmentID: number, facilityID: number) => requests.get(`/admin/staff/${recordID}/options/departments/${departmentID}/facilities/${facilityID}`, new URLSearchParams()),
  fetchProgramOptions: () => requests.get(`/admin/options/programs`, new URLSearchParams()),
  fetchNewFacilityOptions: () => requests.get(`/admin/options/facilities`, new URLSearchParams()),
  fetchTemplate: (recordID: number) => requests.get(`/admin/templates/${recordID}`, new URLSearchParams()),
  fetchCourse: (recordID: number) => requests.get(`/admin/courses/${recordID}`, new URLSearchParams()),
  fetchProgram: (recordID: number) => requests.get(`/admin/programs/${recordID}`, new URLSearchParams()),
  fetchTitles: (recordID: number) => requests.get(`/admin/templates/${recordID}/titles`, new URLSearchParams()),
  fetchTemplateCertificates: (recordID: number) => requests.get(`/admin/templates/${recordID}/certificates`, new URLSearchParams()),
  fetchPresets: () => requests.get(`/admin/presets`, new URLSearchParams()),
  createStaffTitle: (data: any) => requests.post(`/admin/titles/new`, data),
  removeStaffTitle: (recordID: number, data: any) => requests.post(`/admin/titles/${recordID}/remove`, data),
  saveCertificate: (data: any) => requests.post(`/admin/certificates/${data.recordID}`, data),
  addCertificate: (data: any) => requests.post(`/admin/certificates`, data),
  saveInstitution: (data: any) => requests.post(`/admin/institutions/${data.recordID}`, data),
  addInstitution: (data: any) => requests.post(`/admin/institutions`, data),
  updatePreset: (recordID: number, preset: string) => requests.post(`/admin/titles/${recordID}`, { preset: preset }),
  savePreset: (presets: any) => requests.post(`/admin/presets`, { presets: presets }),
  createPreset: (data: any) => requests.post(`/admin/presets/new`, data),
  removePreset: (data: any) => requests.post(`/admin/presets/remove`, data),
  presetOptions: (params: URLSearchParams) => requests.get(`/admin/options/presets`, params),
  saveSettings: (data: any) => requests.post(`/admin/settings`, data),
  addTheme: (data: any) => requests.post(`/admin/themes`, data),
  saveTheme: (recordID: number, data: any) => requests.post(`/admin/themes/${recordID}`, data),
  removeTheme: (recordID: number) => requests.post(`/admin/themes/${recordID}/remove`, {}),
  addTemplateTitle: (data: any) => requests.post(`/admin/templates/${data.recordID}/titles`, data),
  saveTemplateTitle: (recordID: number, data: any) => requests.post(`/admin/templates/${data.recordID}/titles/${recordID}`, data),
  removeTemplateTitle: (recordID: number, data: any) => requests.post(`/admin/templates/${data.recordID}/titles/${recordID}/remove`, {}),
  createTemplateCertificate: (recordID: number, data: any) => requests.post(`/admin/templates/${recordID}/certificates`, data),
  saveTemplateCertificate: (recordID: number, data: any) => requests.post(`/admin/templates/${recordID}/certificates/${data.recordID}`, data),
  removeTemplateCertificate: (recordID: number, data: any) => requests.post(`/admin/templates/${recordID}/certificates/${data.recordID}/remove`, {}),
  addStaff: (data: any) => requests.post(`/admin/staff`, data),
  saveStaff: (data: any) => requests.post(`/admin/staff/${data.recordID}`, data),
  addFacility: (recordID: number, data: any) => requests.post(`/admin/staff/${recordID}/facilities`, data),
  removeFacility: (recordID: number, departmentID: number, facilityID: number) => requests.post(`/admin/staff/${recordID}/departments/${departmentID}/facilities/${facilityID}/remove`, {}),
  saveDepartmentPermissions: (recordID: number, departmentID: number, data: any) => requests.post(`/admin/staff/${recordID}/permissions/department/${departmentID}`, data),
  saveFacilityPermissions: (recordID: number, departmentID: number, facilityID: number, data: any) => requests.post(`/admin/staff/${recordID}/permissions/departments/${departmentID}/facilities/${facilityID}`, data),
  addHousing: (recordID: number, data: any) => requests.post(`/admin/facilities/${recordID}/housing`, data),
  saveHousing: (recordID: number, data: any) => requests.post(`/admin/facilities/${recordID}/housing/${data.recordID}`, data),
  removeHousing: (recordID: number, housingID: number) => requests.post(`/admin/facilities/${recordID}/housing/${housingID}/remove`, {}),
  addLocation: (recordID: number, data: any) => requests.post(`/admin/facilities/${recordID}/locations`, data),
  saveLocation: (recordID: number, data: any) => requests.post(`/admin/facilities/${recordID}/locations/${data.recordID}`, data),
  removeLocation: (recordID: number, locationID: number) => requests.post(`/admin/facilities/${recordID}/locations/${locationID}/remove`, {}),
  addProgram: (data: any) => requests.post(`/admin/programs`, data),
  saveProgram: (data: any) => requests.post(`/admin/programs/${data.recordID}`, data),
  addCourse: (program: number, data: any) => requests.post(`/admin/programs/${program}/courses`, data),
  saveCourse: (data: any) => requests.post(`/admin/courses/${data.recordID}`, data),
  addTemplate: (course: number, data: any) => requests.post(`/admin/courses/${course}/templates`, data),
  saveTemplate: (data: any) => requests.post(`/admin/templates/${data.recordID}`, data),
  createFacility: (data: any) => requests.post(`/admin/facilities`, data),
  saveFacility: (data: any) => requests.post(`/admin/facilities/${data.recordID}`, data),
  saveGuide: (recordID: number, data: any) => requests.post(`/admin/guides/${recordID}`, data),
  removeGuide: (recordID: number) => requests.post(`/admin/guides/${recordID}/remove`, {}),
  addCategory: (value: any) => requests.post(`/admin/categories`, { category: value }),
  removeCategory: (recordID: number) => requests.post(`/admin/categories/${recordID}/remove`, {}),
  addGuide: (recordID: number) => requests.post(`/admin/categories/${recordID}`, {}),
  saveNotices: (data: any) => requests.post(`/admin/news/notices`, data),
  saveAnnouncements: (data: any) => requests.post(`/admin/news/announcements`, data),
  clearNotices: () => requests.post(`/admin/news/clear/notices`, {}),
  clearAnnouncements: () => requests.post(`/admin/news/clear/announcements`, {}),
  resetPassword: (recordID: number) => requests.post(`/staff/${recordID}/reset/password`, {}),
  saveSchool: (data: any) => requests.post(`/admin/schools/${data.id}`, data),
  removeSchool: (recordID: number) => requests.post(`/admin/schools/${recordID}/remove`, {}),
  addRequest: (data: any) => requests.post(`/admin/schools/requests`, data),
  removeRequest: (data: any) => requests.post(`/admin/schools/requests/${data.id}/remove`, data),
  fetchMenuItems: () => requests.get(`/admin/menus`, new URLSearchParams()),
  fetchSurveys: () => requests.get(`/admin/surveys`, new URLSearchParams()),
  fetchCareers: () => requests.get(`/admin/careers`, new URLSearchParams()),
  fetchSurveyQuestions: (recordID: number) => requests.get(`/admin/surveys/${recordID}/questions`, new URLSearchParams()),
  fetchSurveyOptions: (recordID: number) => requests.get(`/admin/groups/${recordID}/options`, new URLSearchParams()),
  reorderMenuItems: (items: any[]) => requests.post(`/admin/menus/reorder`, { items: items }),
  reorderSurveyQuestions: (questions: any[]) => requests.post(`/admin/questions/reorder`, { questions: questions }),
  reorderSurveyOptions: (options: any[]) => requests.post(`/admin/options/reorder`, { options: options }),
  addMenuCategory: (data: any) => requests.post(`/admin/menus/categories`, data),
  removeMenuCategory: (data: any) => requests.post(`/admin/menus/categories/remove`, data),
  addMenuItem: (data: any) => requests.post(`/admin/menus`, data),
  addSurvey: (data: any) => requests.post(`/admin/surveys`, data),
  saveSurvey: (data: any) => requests.post(`/admin/surveys/${data.recordID}`, data),
  removeSurvey: (recordID: number) => requests.post(`/admin/surveys/${recordID}/remove`, {}),
  addCareer: (data: any) => requests.post(`/admin/careers`, data),
  saveCareer: (data: any) => requests.post(`/admin/careers/${data.recordID}`, data),
  removeCareer: (recordID: number) => requests.post(`/admin/careers/${recordID}/remove`, {}),
  addSurveyQuestion: (recordID: number, data: any) => requests.post(`/admin/surveys/${recordID}/questions`, data),
  saveSurveyQuestion: (data: any) => requests.post(`/admin/questions/${data.recordID}`, data),
  removeSurveyQuestion: (recordID: number) => requests.post(`/admin/questions/${recordID}/remove`, {}),
  addSurveyOption: (recordID: number, data: any) => requests.post(`/admin/groups/${recordID}/options`, data),
  saveSurveyOption: (data: any) => requests.post(`/admin/options/${data.recordID}`, data),
  removeSurveyOption: (recordID: number) => requests.post(`/admin/options/${recordID}/remove`, {}),
  addSurveyOptionGroup: (data: any) => requests.post(`/admin/groups`, data),
  saveSurveyOptionGroup: (data: any) => requests.post(`/admin/groups/${data.recordID}`, data),
  removeSurveyOptionGroup: (recordID: number) => requests.post(`/admin/groups/${recordID}/remove`, {}),
  saveMenuItem: (data: any) => requests.post(`/admin/menus/${data.recordID}`, data),
  removeMenuItem: (recordID: number) => requests.post(`/admin/menus/${recordID}/remove`, {}),
  removeHoliday: (recordID: number) => requests.post(`/admin/holidays/${recordID}/remove`, {}),
  addHoliday: (data: any) => requests.post(`/admin/holidays`, data),
  saveHoliday: (data: any) => requests.post(`/admin/holidays/${data.recordID}`, data),
  deleteFacility: (recordID: number) => requests.post(`/admin/facilities/${recordID}/remove`, {}),
  archiveProgram: (recordID: number, data: any) => requests.post(`/admin/programs/${recordID}/archive`, data),
  restoreProgram: (recordID: number) => requests.post(`/admin/programs/${recordID}/restore`, {}),
  removeProgram: (recordID: number, data: any) => requests.post(`/admin/programs/${recordID}/remove`, data),
  archiveCourse: (recordID: number, data: any) => requests.post(`/admin/courses/${recordID}/archive`, data),
  restoreCourse: (recordID: number) => requests.post(`/admin/courses/${recordID}/restore`, {}),
  removeCourse: (recordID: number, data: any) => requests.post(`/admin/courses/${recordID}/remove`, data),
  archiveTemplate: (recordID: number, data: any) => requests.post(`/admin/templates/${recordID}/archive`, data),
  restoreTemplate: (recordID: number) => requests.post(`/admin/templates/${recordID}/restore`, {}),
  updateNotifications: (recordID: number, data: any) => requests.post(`/admin/staff/${recordID}/notifications`, data),
  saveReportDescription: (data: any) => requests.post(`/admin/reports/${data.report}/description`, data),
  uploadImage: (data: any) => requests.put(`/admin/upload_image`, data),
  unfavorite: (recordID: number) => requests.post(`/admin/unfavorite/${recordID}`, {}),
  favoriteTemplate: (recordID: number) => requests.post(`/admin/templates/${recordID}/favorite`, {}),
  unfavoriteTemplate: (recordID: number) => requests.post(`/admin/templates/${recordID}/unfavorite`, {}),
  favoriteSchedules: (params: URLSearchParams) => requests.post(`/admin/schedules/favorite`, params),
  unfavoriteSchedules: () => requests.post(`/admin/schedules/unfavorite`, {}),
}

const Reports = {
  fetchReportFacilities: (report: string) => requests.get(`/reports/${report}/facilities`, new URLSearchParams()),
  fetchDescription: (report: string) => requests.get(`/reports/${report}/description`, new URLSearchParams()),
  fetchTypeDescription: (report: string, type: string) => requests.get(`/reports/${report}/description/${type}`, new URLSearchParams()),
  fetchANDs: (params: URLSearchParams) => requests.get(`/reports/ands`, params),
  fetchAttendance: (params: URLSearchParams) => requests.get(`/reports/attendance`, params),
  fetchQuarterly: (params: URLSearchParams) => requests.get(`/reports/quarterly`, params),
  fetchEducationalHistory: (params: URLSearchParams) => requests.get(`/reports/educationalhistory`, params),
  fetchRecidivism: (params: URLSearchParams) => requests.get(`/reports/recidivism`, params),
  fetchRoster: (params: URLSearchParams) => requests.get(`/reports/roster`, params),
  fetchTABE: (params: URLSearchParams) => requests.get(`/reports/tabe`, params),
  fetchGED: (params: URLSearchParams) => requests.get(`/reports/ged`, params),
  fetchHiSET: (params: URLSearchParams) => requests.get(`/reports/hiset`, params),
  fetchKamakani: (params: URLSearchParams) => requests.get(`/reports/kamakani`, params),
  fetchDescriptions: (params: URLSearchParams) => requests.get(`/reports/descriptions`, params),
  fetchUtilization: (params: URLSearchParams) => requests.get(`/reports/utilization`, params),
  fetchTracing: (params: URLSearchParams) => requests.get(`/reports/tracing`, params),
  fetchCSPR: (params: URLSearchParams) => requests.get(`/reports/cspr`, params),
  fetchFunding: (params: URLSearchParams) => requests.get(`/reports/funding`, params),
  fetchDOE: (params: URLSearchParams) => requests.get(`/reports/doe`, params),
  fetchClassSchedule: (params: URLSearchParams) => requests.get(`/reports/schedule`, params),
  fetchNotificationsWarning: (params: URLSearchParams) => requests.get(`/reports/notifications/warning`, params),
  fetchClassEnrollment: (params: URLSearchParams) => requests.get(`/reports/notifications/enrollment`, params),
  fetchUnexcused: (params: URLSearchParams) => requests.get(`/reports/notifications/unexcused`, params),
  fetchTranscriptRequest: (params: URLSearchParams) => requests.get(`/reports/transcript`, params),
  fetchEdHistory: (params: URLSearchParams) => requests.get(`/reports/edhistory`, params),
  fetchF840X: (params: URLSearchParams) => requests.get(`/reports/F840X`, params),
  favorite: (params: URLSearchParams) => requests.post(`/reports/favorite`, params),
  unfavorite: (params: URLSearchParams) => requests.post(`/reports/unfavorite`, params)
}

const Dashboard = {
  fetchOptions: () => requests.get(`/dashboard/options`, new URLSearchParams()),
  fetchNotifications: (params: URLSearchParams) => requests.get(`/dashboard/notifications`, params),
  fetchNews: (params: URLSearchParams) => requests.get(`/dashboard/news`, params),
  fetchStaff: (params: URLSearchParams) => requests.get(`/dashboard/staff`, params),
  fetchOrder: () => requests.get(`/dashboard/order`, new URLSearchParams()),
  fetchOrphans: (params: URLSearchParams) => requests.get(`/dashboard/orphans`, params),
  fetchApprovals: (params: URLSearchParams) => requests.get(`/dashboard/approvals`, params),
  fetchTABEs: (params: URLSearchParams) => requests.get(`/dashboard/tabes`, params),
  fetchSchools: (params: URLSearchParams) => requests.get(`/dashboard/schools`, params),
  fetchTranscripts: (params: URLSearchParams) => requests.get(`/dashboard/transcripts`, params),
  fetchFunding: (params: URLSearchParams) => requests.get(`/dashboard/funding`, params),
  fetchInstructors: (params: URLSearchParams) => requests.get(`/dashboard/instructors`, params),
  fetchClasses: (params: URLSearchParams) => requests.get(`/dashboard/classes`, params),
  fetchArrivals: (params: URLSearchParams) => requests.get(`/dashboard/arrivals`, params),
  fetchPending: (params: URLSearchParams) => requests.get(`/dashboard/pending`, params),
  fetchAttendance: (params: URLSearchParams) => requests.get(`/dashboard/attendance`, params),
  fetchAnds: (params: URLSearchParams) => requests.get(`/dashboard/ands`, params),
  fetchSurveys: () => requests.get(`/dashboard/surveys`, new URLSearchParams()),
  submitSurvey: (recordID: number, data:any) => requests.post(`/dashboard/surveys/${recordID}`, data),
  refuseSurvey: (recordID: number) => requests.post(`/dashboard/surveys/${recordID}/refuse`, {}),
  extendClass: (recordID: number, data:any) => requests.post(`/dashboard/extend/class/${recordID}`, data),
  extendStaff: (recordID: number, date: string, all: boolean) => requests.post(`/dashboard/extend/staff/${recordID}`, { date: date, all: all }),
  archiveStaff: (recordID: number) => requests.post(`/dashboard/archive/${recordID}`, {}),
  expireStaff: (recordID: number) => requests.post(`/dashboard/expire/${recordID}`, {}),
  assignOrphan: (data: any) => requests.post(`/dashboard/orphan`, data),
  assignFunding: (data: any) => requests.post(`/dashboard/funding`, data),
  assignInstructor: (data: any) => requests.post(`/dashboard/instructor`, data),
  combineSuggestion: (suggestionID: any) => requests.post(`/dashboard/suggestions/${suggestionID}/combine`, {}),
  ignoreSuggestion: (suggestionID: any) => requests.post(`/dashboard/suggestions/${suggestionID}/ignore`, {}),
  updateStaffNotification: (data:any) => requests.post(`/dashboard/notifications`, data),
  updateStaffCollapse: (data:any) => requests.post(`/dashboard/collapse`, data),
  reorder: (order: any[]) => requests.post(`/dashboard/reorder`, { order: order }),
}

export default {
  Auth,
  Data,
  Schedules,
  Inmates,
  Classes,
  Admin,
  Dashboard,
  Reports
}
