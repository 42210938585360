import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Label,
  Accordion,
  Tab,
  Select,
  Button,
  TextArea,
  Header,
  Divider,
  Message,
  Icon,
  Popup,
  Table,
  Modal
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateCareers extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchEducation(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      const careerPanels = this.props.inmate.education.data.careers.map((career:any) => {
        return { key: career.recordID, title: { content:
          <Fragment>
            {career.name}
          </Fragment>
        }, content: { content:
          <Fragment>
            <Tab menu={{ secondary: true, pointing: true }} panes={[
              { menuItem: 'Path', render: () => <div style={{whiteSpace:'normal'}}>{career.templates.map((template:any, t:number) => <Label key={t} style={{marginRight:4,marginBottom:4}}>{template.name}</Label>)}</div> },
            ]} /> 
          </Fragment>
        }}
      })

      return (
        <Container fluid>
          <Header as='h3' style={{marginTop:10,display:'inline-block'}}>Career Pathways</Header>
          <Accordion styled panels={careerPanels}/>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(InmateCareers as any)