import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import Cleave from "cleave.js/react"
import {
  Container,
  Icon,
  Form,
  Button,
  Dropdown,
  Input,
  Select,
  TextArea,
  Grid,
  Header,
  Message,
  Divider,
  Search,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { career: PanesStore.Career, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class CareerPane extends React.PureComponent<PaneProps> {
    public render() {
        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='suitcase' /> {this.props.career.data.name}</div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header as='h3'>Info</Header>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Name'
                  value={this.props.career.info.name||''}
                  onChange={(e:any, data:any) => this.props.editCareerField(this.props.num, 'name', data.value.validate())}
                  width={12}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Select}
                  label='Code'
                  className={this.props.print ? 'transparent' : ''}
                  width={4}
                  value={this.props.career.info.code ? this.props.career.info.code.split('') : []}
                  onChange={(e:any, d:any) => {
                    if (d.value.length <= 3) {
                      this.props.editCareerField(this.props.num, 'code', d.value.sort().join(''))
                    }
                  }}
                  options={[
                    { key: 'R', text: 'R', value: 'R' },
                    { key: 'I', text: 'I', value: 'I' }, 
                    { key: 'A', text: 'A', value: 'A' },
                    { key: 'S', text: 'S', value: 'S' },
                    { key: 'E', text: 'E', value: 'E' },
                    { key: 'C', text: 'C', value: 'C' }
                  ]}
                  multiple
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={TextArea}
                  label='Narrative'
                  value={this.props.career.info.narrative||''}
                  onChange={(e:any, data:any) => this.props.editCareerField(this.props.num, 'narrative', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                  width={16}
                  rows={3}
                />
              </Form.Group>
              <Grid columns={2} className='nopad' style={{marginTop:10}}>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h3'>Prerequisites</Header>
                    <Form.Field
                      control={Select}
                      options={_.map(this.props.career.options.templates, (template:any) => { return { ...template, content: <Fragment><div style={{padding:'.78571429rem 1.14285714rem',backgroundColor:(_.any(_.filter(this.props.career.info.templates, (template:any) => template.pre), (t:any) => t.recordID == template.key) ? '#fffaf3' : 'inherit')}}><span className='text' style={{display:'block'}}>{template.text}</span><span className='description' style={{display:'block',float:'none',color:'rgba(0,0,0,.4)',margin:0,marginTop:4}}>{template.category}</span></div></Fragment> } })}
                      value={null}
                      onChange={(e:any, data:any) => this.props.selectCareer(this.props.num, {key: data.value, text: data.options.find((opt:any) => opt.value === data.value).text}, true)}
                      search
                    />
                    <div className='career-labels' style={{marginTop:10,whiteSpace:'normal'}}>
                      {_.map(_.filter(this.props.career.info.templates, (template:any) => template.pre), (template:any, t:number) => <Label key={t} style={{marginTop:4}} onClick={() => this.props.removeTemplate(this.props.num, template.recordID)}>{template.name}</Label>)}
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h3'>Requisites</Header>
                    <Form.Field
                      control={Select}
                      options={_.map(this.props.career.options.templates, (template:any) => { return { ...template, content: <Fragment><div style={{padding:'.78571429rem 1.14285714rem',backgroundColor:(_.any(_.filter(this.props.career.info.templates, (template:any) => !template.pre), (t:any) => t.recordID == template.key) ? '#fffaf3' : 'inherit')}}><span className='text' style={{display:'block'}}>{template.text}</span><span className='description' style={{display:'block',float:'none',color:'rgba(0,0,0,.4)',margin:0,marginTop:4}}>{template.category}</span></div></Fragment> } })}
                      value={null}
                      onChange={(e:any, data:any) => this.props.selectCareer(this.props.num, {key: data.value, text: data.options.find((opt:any) => opt.value === data.value).text}, false)}
                      search
                    />
                    <div className='career-labels' style={{marginTop:10,whiteSpace:'normal'}}>
                      {_.map(_.filter(this.props.career.info.templates, (template:any) => !template.pre), (template:any, t:number) => <Label key={t} style={{marginTop:4}} onClick={() => this.props.removeTemplate(this.props.num, template.recordID)}>{template.name}</Label>)}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h3'>Students</Header>
                    <Search
                      loading={this.props.career.inmate.loading}
                      onResultSelect={(e:any, data:any) => this.props.selectCareerInmate(this.props.num, data.result)}
                      onSearchChange={(e:any, data:any) => this.props.searchCareers(this.props.num, data.value as string)}
                      results={this.props.career.options.inmates}
                      value={this.props.career.inmate.value}
                      style={{display:'inline-block'}}
                    />
                    <div className='career-labels' style={{marginTop:10,whiteSpace:'normal'}}>
                      {_.map(this.props.career.info.inmates, (inmate:any, i:number) => <Label key={i} style={{marginTop:4}} onClick={() => this.props.removeCareerInmate(this.props.num, inmate.recordID)}>{inmate.name}</Label>)}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider />
              {this.props.print ? null : <Button positive content='Save' onClick={_.debounce(() => this.props.saveCareer(this.props.num, 'career'), 10000, true)} />}
              {this.props.print ? null : <Button negative content='Remove' onClick={_.debounce(() => this.props.removeCareer(this.props.num), 10000, true)} />}
            </Form>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { career: ownProps.career, num: ownProps.num, print: ownProps.options.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(CareerPane as any)